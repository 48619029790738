@import url(https://fonts.googleapis.com/css?family=Lato:700,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: site-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.signup-form {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.signup-form .signup-card {
    width: 450px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 25px 50px -10px rgba(0,0,0,.1);
    margin-bottom: 20px;
    padding: 20px;
}

.login-form-item {
    flex-direction: row;
    width: 100%;
    margin-bottom: 10px !important;
    justify-content: space-between;
}

.login-form-item label {
    float: left;
    margin-bottom: 0 !important;
    line-height: 25px;
    font-size: 14px !important;
}

.login-form-item-actions {
    flex-direction: column;
    min-width: 400px;
}

.login-form-input {
    /* width: 300px */
}
.account-menuform {
    display: flex;
}

.account-form {
    margin-left: 40px;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.account-form-input {
    width: 250px !important;
}

.single-form {
    padding: 24px !important;
    /* background: #fbfbfb; */
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    margin: 20px 0 !important;
    width: 550px;
}

.plan-details-since {
    text-align: right;
    width: 100%;
    display: block;
}

.billing-list {
    margin: 0 0 20px 0 !important;
}

.billing-list li {
    padding: 5px 0 !important;
}

.billing-list li span.billing-list-item-title {
    font-weight: 600;
    width: 110px;
    display: inline-block;
}
.header-container {
    display: flex;
    flex-direction: row;
    box-shadow: 0 1px 2px 0 rgba(60,64,67,.3), 0 2px 6px 2px rgba(60,64,67,.15);
    z-index: 1;
  }
  
  .checkout-page .header-container {
      display: none;
  }
  
  .header-left {
    width: 50%;
    display: flex;
  }
  
  .header-right {
    width: 50%;
    padding-right: 60px !important;
    float: right;
    display: flex;
    justify-content: flex-end;
  
  }
  
  .logo {
    padding-left: 25px !important;
    margin: 0 20px;
    color: black;
  }
  
  .notify-item {
    top: 5px !important;
  }
  
  .menu-credits-point:hover {
    color: #424242 !important;
  }
  
  .header-notice {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    background: white;
    font-weight: 500;
    letter-spacing: 0.1px;
  }
body {
  /* background-color: #f4f5f6; */
}

.App {
  /* text-align: center; */
  min-height: 100vh;
  display: flex;
}

.app-content {
  flex-direction: column;
  padding: 25px 25px;
  display: flex;
  /* justify-content: center; */
  background: #fff;
  min-height: 280px;
}

.breadcrumbs-container {
  display: flex;
}

.app-breadcrumbs {
  height: 30px;
  line-height: 30px;
  margin-bottom: 16px;
  background: #f8f8f8;
  border-radius: 5px;
  padding: 0 10px;
  cursor: pointer;
}

.app-breadcrumbs a {
  line-height: 30px;
  padding: 0 8px;
  color: #1890ff;
}

.warning-row {
  background-color: #ffcccc; /* Light red background */
}

