.header-container {
    display: flex;
    flex-direction: row;
    box-shadow: 0 1px 2px 0 rgba(60,64,67,.3), 0 2px 6px 2px rgba(60,64,67,.15);
    z-index: 1;
  }
  
  .checkout-page .header-container {
      display: none;
  }
  
  .header-left {
    width: 50%;
    display: flex;
  }
  
  .header-right {
    width: 50%;
    padding-right: 60px !important;
    float: right;
    display: flex;
    justify-content: flex-end;
  
  }
  
  .logo {
    padding-left: 25px !important;
    margin: 0 20px;
    color: black;
  }
  
  .notify-item {
    top: 5px !important;
  }
  
  .menu-credits-point:hover {
    color: #424242 !important;
  }
  
  .header-notice {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    background: white;
    font-weight: 500;
    letter-spacing: 0.1px;
  }