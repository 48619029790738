@import '~antd/dist/antd.css';
@import url('https://fonts.googleapis.com/css?family=Lato:700,900&display=swap');

body {
  /* background-color: #f4f5f6; */
}

.App {
  /* text-align: center; */
  min-height: 100vh;
  display: flex;
}

.app-content {
  flex-direction: column;
  padding: 25px 25px;
  display: flex;
  /* justify-content: center; */
  background: #fff;
  min-height: 280px;
}

.breadcrumbs-container {
  display: flex;
}

.app-breadcrumbs {
  height: 30px;
  line-height: 30px;
  margin-bottom: 16px;
  background: #f8f8f8;
  border-radius: 5px;
  padding: 0 10px;
  cursor: pointer;
}

.app-breadcrumbs a {
  line-height: 30px;
  padding: 0 8px;
  color: #1890ff;
}

.warning-row {
  background-color: #ffcccc; /* Light red background */
}
